import './App.css';

import { useEffect } from 'react';

/* logotipes */

import HestLogotipe from './assets/imgs/logotipe/hest-logotipe.svg';
import AgrocampoLogotipe from './assets/imgs/logotipe/agrocampo-logotipe.svg';
import OrruLogotipe from './assets/imgs/logotipe/orru-logotipe.svg';
import DJonesLogotipe from './assets/imgs/logotipe/djones-logotipe.svg';
import ValedoSolLogotipe from './assets/imgs/logotipe/vale-do-sol-logotipe.svg';
import DitinhoLogotipe from './assets/imgs/logotipe/ditinho-logotipe.svg';
import PolimedLogotipe from './assets/imgs/logotipe/polimed-logotipe.svg';
import SERPREVlogotipe from './assets/imgs/logotipe/SERPREV-logotipe.svg';
import MOCAPlogotipe from './assets/imgs/logotipe/mocap-logotipe.svg';
import CaféBotecoLogotipe from './assets/imgs/logotipe/cafe-boteco-logotipe.svg';

/* icons */

import InstagramIcons from './assets/imgs/icons/instagram.svg';
import WhatsappIcons from './assets/imgs/icons/whatsapp.svg';
import LinkedinIcon from './assets/imgs/icons/linkedin.svg';
import { X, Menu } from 'lucide-react';

/* images */

import PGRimage from './assets/imgs/sevices-imgs/PGR.png';
import PCMSOimage from './assets/imgs/sevices-imgs/PCMSO.png';
import LTCATimage from './assets/imgs/sevices-imgs/LTCAT.png';
import TreinamentodeNrsImage from './assets/imgs/sevices-imgs/Treinamento-de-Nrs.png';
import BrigadadeIncêndioImage from './assets/imgs/sevices-imgs/Brigada-de-incêndio.png';
import InspeçãoBombeirosImage from './assets/imgs/sevices-imgs/Inspeção-bombeiros.png';
import AVCBimage from './assets/imgs/sevices-imgs/AVCB.png';
import ProjetoCivilImage from './assets/imgs/sevices-imgs/Projeto-civil.png';
import PontoComLogotipe from './assets/imgs/logotipe/ponto-com-logotipe.svg';
import GRdemolidoraLogotipe from './assets/imgs/logotipe/GR-demolidora-logotipe.svg';
import homeBackground from './assets/imgs/backgrounds/tobias-keller-2ecH5Lw3zSk-unsplash.jpg';

/* scripts */

function App() {
  useEffect(() => {
    const hamburguerMenuBtn = document.querySelector("#hamburguer-menu-icon");
    const menu = document.querySelector("#menu");
    const closeMenuBtn = document.querySelector("#close-menu-icon");
    const menuBtn = document.querySelectorAll("#menu-btn");

    hamburguerMenuBtn.onclick = function () {
      menu.classList.add("visible");
    }

    closeMenuBtn.onclick = function () {
      menu.classList.remove("visible");
    }

    menuBtn.forEach((btn) => {
      btn.onclick = function(){
        menu.classList.remove("visible");
      }
    })

    /* build scroll anim ===================================================== */

    const buildContainer = document.querySelector("#building-container");
    const leftBuildPart = document.querySelector("#left-building-part");
    const rightBuildPart = document.querySelector("#right-building-part");

    const aboutContainer = document.querySelector("#about");

    window.addEventListener('scroll', function () {
      const buildHeight = buildContainer.clientHeight / 10 * 2;
      const isBelowBuildHeight = window.pageYOffset < buildHeight;

      const target = this.window.scrollY * 0.5;

      leftBuildPart.classList.toggle("hidden", !isBelowBuildHeight);
      rightBuildPart.classList.toggle("hidden", !isBelowBuildHeight);

      if (target < aboutContainer) {
        console.log("1");
      } else if (target >= aboutContainer) {
        console.log("2");
      }
    });

    /* menu scroll ====================================================== */

    function scrollToSection(event) {
      event.preventDefault();
      
      const targetId = event.target.getAttribute("href");
      const targetElement = document.querySelector(targetId);

      targetElement.scrollIntoView({ behavior: "smooth" });
    }

    const menuLinks = document.querySelectorAll("#menu-btn");
    const footerMenuLinks = document.querySelectorAll("#footer-menu-btn");

    menuLinks.forEach(link => {
      link.addEventListener("click", scrollToSection);
    });
    footerMenuLinks.forEach(link => {
      link.addEventListener("click", scrollToSection);
    });

  });

  /* Vertical Height ==================================================== */

  let vh = window.innerHeight * 0.01;

  document.documentElement.style.setProperty('--vh', `${vh}px`);

  return (
    <div className="App">

      {/* menu */}

      <div id='menu'>
        <nav>
          <a id='menu-btn' href="#about">SOBRE</a>
          <a id='menu-btn' href="#services">SERVIÇOS</a>
          <a id='menu-btn' href="#companies-container">COM QUEM JÁ TRABALHAMOS</a>
          <a id='menu-btn' href="#contact">CONTATO</a>
          <div>
            <a id='menu-btn' href="https://www.instagram.com/hestoficial/" target="_blank"><img src={InstagramIcons} alt="instagram-logo" width={20} /></a>
            <a id='menu-btn' href="https://wa.me/+5519996697456" target="_blank"><img src={WhatsappIcons} alt="whatsapp-logo" width={20} /></a>
            <a id='menu-btn' href="https://www.linkedin.com/company/hestoficial" target="_blank"><img src={LinkedinIcon} alt="linkedin-logo" width={20} /></a>
          </div>
          <X id="close-menu-icon" size={35} />
        </nav>
      </div>

      <Menu id='hamburguer-menu-icon' size={35} />

      {/* home */}

      <img id='home-logotipe' src={HestLogotipe} alt="hest-logotipe" style={{ height: "6%", width: "auto" }} />
      <div id='home'></div>
      <div id='building-container'>
        <div id='left-building-part'>

        </div>
        <div id='right-building-part'>

        </div>
      </div>

      {/* about */}

      <div id='about'>
        <div id='about-companie'>
          <img src={HestLogotipe} alt="hest-logotipe" style={{ height: "auto", width: "20%" }} />
          <p>A HEST é uma empresa fundada por Fernando Hipólito, um dedicado técnico em segurança do trabalho, e sua filha, Talissa Hipólito, uma talentosa engenheira civil. Especializada em oferecer serviços de segurança do trabalho, a HEST visa garantir ambientes laborais seguros e saudáveis para outras empresas. Com um compromisso inabalável com a proteção dos trabalhadores, a HEST tornou-se referência em seu setor em um curto período de tempo. Seu trabalho conjunto combina conhecimentos técnicos e inovação, proporcionando soluções eficazes e personalizadas às necessidades de cada cliente.</p>
        </div>
        <div id='about-professionals'>
          <div id='professional-perfil'>
            <h3>Fernando Hipólito</h3>
            <p>Fernando Hipólito é um empreendedor e especialista em Segurança do Trabalho. Iniciou seu curso técnico nessa área e, após concluir, dedicou-se a auxiliar empresas a se adequarem às normas vigentes. Com sua filha, fundou a empresa HEST, focada em proporcionar soluções em segurança para o ambiente de trabalho. Paralelamente, Fernando também dedicou seu tempo a serviços voluntários, sendo responsável pela Segurança do Trabalho na Congregação Cristã no Brasil, na região de Amparo, durante longo período. Seu comprometimento e conhecimento têm contribuído para criar ambientes mais seguros e protegidos para todos.</p>
          </div>
          <div id='professional-perfil' style={{ borderBottom: "none" }}>
            <h3>Talissa Hipólito</h3>
            <p>Talissa Hipólito é uma engenheira civil formada recentemente e demonstra grande paixão pela área de construção e segurança. Após concluir sua graduação, decidiu se especializar em Segurança do Trabalho, buscando aprimorar suas habilidades e conhecimentos nessa área específica. Investiu em diversos cursos e concluiu uma pós-graduação, adquirindo uma qualificação sólida e um comprometimento excepcional em assegurar ambientes seguros para os trabalhadores. Sua dedicação e expertise fizeram dela uma referência no setor de engenharia civil e segurança ocupacional.</p>
          </div>
        </div>
      </div>

      {/* services */}

      <div id='services'>
        <h2>Nossos Serviços</h2>
        <div>
          <div id='service-container'>
            <div><img src={PGRimage} alt="service-img" width={290} /></div>
            <h3>PGR</h3>
            <p>O Laudo de PGR é um documento que identifica os riscos em um ambiente de trabalho, elaborado por especialistas em segurança ocupacional. Ele propõe medidas para controlar esses riscos e garantir a conformidade com as normas regulamentadoras.</p>
          </div>
          <div id='service-container'>
            <div><img src={LTCATimage} alt="service-img" width={290} /></div>
            <h3>LTCAT</h3>
            <p>O LTCAT é um documento que avalia os riscos ambientais no trabalho, identificando agentes prejudiciais à saúde dos funcionários. Ele é utilizado para determinar a insalubridade ou periculosidade e implementar medidas de segurança.</p>
          </div>
          <div id='service-container'>
            <div><img src={PCMSOimage} alt="service-img" width={290} /></div>
            <h3>PCMSO</h3>
            <p>PCMSO é o Programa de Controle Médico de Saúde Ocupacional, uma obrigação legal para empresas que visa à saúde dos trabalhadores por meio de exames médicos, avaliações clínicas e medidas preventivas no ambiente de trabalho.</p>
          </div>
          <div id='service-container'>
            <div><img src={TreinamentodeNrsImage} alt="service-img" width={290} /></div>
            <h3>Treinamento de Nrs</h3>
            <p>Os treinamentos de NR garantem a segurança dos trabalhadores, prevenindo acidentes e doenças. Eles promovem a conscientização e o cumprimento da legislação, resultando em ambientes de trabalho mais seguros e produtivos.</p>
          </div>
          <div id='service-container'>
            <div><img src={BrigadadeIncêndioImage} alt="service-img" width={290} /></div>
            <h3>Formação de Brigada de Incêndio</h3>
            <p>As Brigadas de Incêndio são essenciais para prevenir incêndios, agindo de forma rápida e eficiente para proteger vidas e propriedades. Além disso, promovem a conscientização sobre medidas de segurança.</p>
          </div>
          <div id='service-container'>
            <div><img src={InspeçãoBombeirosImage} alt="service-img" width={290} /></div>
            <h3>Projeto para Vistoria dos Bombeiros</h3>
            <p>A vistoria dos bombeiros em uma empresa é essencial para garantir a segurança dos ocupantes. O projeto avalia as condições de prevenção e combate a incêndios, identificando e corrigindo riscos para proteger vidas e patrimônio.</p>
          </div>
          <div id='service-container'>
            <div><img src={AVCBimage} alt="service-img" width={290} /></div>
            <h3>AVCB</h3>
            <p>AVCB é um laudo emitido pelos Bombeiros que certifica a conformidade de uma edificação às normas de segurança contra incêndios. É obtido após vistoria técnica que verifica equipamentos e medidas de prevenção e combate a incêndios, sendo essencial para garantir a segurança e regularidade de estabelecimentos.</p>
          </div>
          <div id='service-container'>
            <div><img src={ProjetoCivilImage} alt="service-img" width={290} /></div>
            <h3>Projeto Civil em Geral</h3>
            <p>Um bom projeto civil é essencial para garantir a segurança e a funcionalidade de estruturas e edificações. Ele permite uma análise precisa de cargas, materiais e técnicas construtivas, resultando em construções duradouras e eficientes. Além disso, um projeto bem elaborado evita retrabalhos, reduz custos e contribui para a preservação ambiental.</p>
          </div>
        </div>
      </div>

      {/* companies we've worked with */}

      <div id='companies-container'>
        <h2>Algumas empresas com quem já trabalhamos</h2>
        <div id='companies-logotipes'>
          <div>
            <img src={AgrocampoLogotipe} alt="agrocampo-logotipe" />
          </div>
          <div>
            <img src={OrruLogotipe} alt="orru-logotipe" />
          </div>
          <div>
            <img src={DJonesLogotipe} alt="djones-logotipe" />
          </div>
          <div>
            <img src={ValedoSolLogotipe} alt="vale-do-sol-logotipe" />
          </div>
          <div>
            <img src={DitinhoLogotipe} alt="logotipe" />
          </div>
          <div>
            <img src={PolimedLogotipe} alt="logotipe" />
          </div>
          <div>
            <img src={SERPREVlogotipe} alt="logotipe" />
          </div>
          <div>
            <img src={CaféBotecoLogotipe} alt="logotipe" />
          </div>
          <div>
            <img src={PontoComLogotipe} alt="logotipe" />
          </div>
          <div>
            <img src={GRdemolidoraLogotipe} alt="logotipe" />
          </div>
        </div>
      </div>

      {/* contact */}

      <div id='contact'>
        <h2>Fale Conosco</h2>
        <div>
          <div>
            <img src={WhatsappIcons} alt="whatsapp-icon" height={50} />
            <h3>Whatssap:</h3>
            <a href="https://wa.me/+5519996697456" target="_blank">Fernando Hipólito:<br />(19) 9 9669-7456</a>
            <a href="https://wa.me/+5519971538859" target="_blank">Talissa Hipólito:<br />(19) 9 7153-8859</a>
          </div>
          <div>
            <img src={InstagramIcons} alt="instagram-icon" height={50} />
            <h3>Instagram</h3>
            <a href="https://www.instagram.com/hestoficial/" target="_blank" >@hestoficial</a>
          </div>
          <div>
            <img src={LinkedinIcon} alt="linkedin-icon" height={50} />
            <h3>Linkedin</h3>
            <a href="https://www.linkedin.com/company/hestoficial" target="_blank" >/hestoficial</a>
          </div>
        </div>
      </div>

      {/* footer */}

      <footer>
        <nav>
          <a id='footer-menu-btn' href="#about">Sobre</a>
          <a id='footer-menu-btn' href="#services">Serviços</a>
          <a id='footer-menu-btn' href="#companies-container">Com quem já trabalhamos</a>
          <a id='footer-menu-btn' href="#contact">Contato</a>
          <div>
            <a href="https://www.instagram.com/hestoficial/" target="_blank"><img src={InstagramIcons} alt="instagram-logo" width={20} /></a>
            <a href="https://wa.me/+5519996697456" target="_blank"><img src={WhatsappIcons} alt="whatsapp-logo" width={20} /></a>
            <a href="https://www.linkedin.com/company/hestoficial" target="_blank"><img src={LinkedinIcon} alt="linkedin-logo" width={20} /></a>
          </div>
        </nav>
        <section>
          <span>© HAST. Hipolito Assessoria em Segurança do Trabalho - 43.866.032/0001-87 - Rua Adelaide Blotta Marques, 47, Residencial das Posses - 13930-000 - Serra Negra, SP</span>
          <div>Desenvolvido por <a href=""><img src={MOCAPlogotipe} alt="mocap-logotipe" height={20} /></a></div>
        </section>
      </footer>

    </div>
  );
}

export default App;
